module.exports = new Promise(resolve => {
        const remoteUrlWithVersion = window.mfe_mgmtFieldMapper;
        const script = document.createElement('script');
        script.src = remoteUrlWithVersion;
        script.onload = () => {
            const proxy = {
                get: (request) => window.mgmtFieldMapper.get(request),
                init: (arg) => {
                    try {
                        return window.mgmtFieldMapper.init(arg);
                    } catch(e) {
                        console.log('remote container already initialized');
                    }
                }
            };
            resolve(proxy);
        };
        document.head.appendChild(script);
    });;