fetch('/mfe.config.json')
    .then((res) => res.json())
    .then((json) => {
        const { remotes } = json;
        window.mfe_mgmtUI = remotes.mgmtUI;
        window.mfe_mgmtFieldMapper = remotes.mgmtFieldMapper;
        window.mfe_mgmtSiteStateManager = remotes.mgmtSiteStateManager;

        import('./bootstrap');
    });
